import { DqCard, DqCardContent } from "@decentriq/components";
import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBinoculars, faSliders } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, RadioGroup, Typography } from "@mui/joy";
import { memo, useState } from "react";
import {
  AdvertiserAudienceGeneratorType,
  useAdvertiserAudienceGenerator,
} from "../../contexts/AdvertiserAudienceGeneratorContext";
import {
  AdvertiserAudienceGeneratorHeader,
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "..";

type AudienceGeneratorType = {
  value: AdvertiserAudienceGeneratorType;
  title: string;
  description: string;
  icon: IconProp;
};

const AUDIENCE_GENERATOR_TYPES: AudienceGeneratorType[] = [
  {
    description: "Combine and filter your existing audiences.",
    icon: faSliders,
    title: "Rule-based audience",
    value: AdvertiserAudienceGeneratorType.RULES_BASED,
  },
  {
    description:
      "Build an audience with the users most similar to your seed audience.",
    icon: faBinoculars,
    title: "AI lookalike audience",
    value: AdvertiserAudienceGeneratorType.LOOKALIKE,
  },
];

const AdvertiserAudienceGeneratorTypeSelection = memo(() => {
  const { handleBackStep, selectGeneratorType } =
    useAdvertiserAudienceGenerator();
  const [generatorType, setGeneratorType] =
    useState<AdvertiserAudienceGeneratorType | null>(null);
  return (
    <Grid container={true}>
      <Grid mb={3} md={7} xs={12}>
        <AdvertiserAudienceGeneratorHeader />
      </Grid>
      <Grid mb={2} md={7} xs={12}>
        <AudienceGeneratorStepSectionTitle>
          1. Audience type
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Select the type of audience to create: Rule-based for targeted
          precision or AI lookalike to find new customers similar to your top
          ones.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <RadioGroup
        name="generatorType"
        onChange={(event) =>
          setGeneratorType(
            event.target.value as AdvertiserAudienceGeneratorType
          )
        }
        value={generatorType}
      >
        <Grid
          columnSpacing={2}
          container={true}
          mb={2}
          md={7}
          rowSpacing={2}
          xs={12}
        >
          {AUDIENCE_GENERATOR_TYPES.map(
            ({ description, icon, title, value }) => (
              <Grid md={6} xs={12}>
                <DqCard
                  key={value}
                  selectable={{
                    component: "radio",
                    value,
                  }}
                >
                  <DqCardContent gap={1}>
                    <FontAwesomeIcon icon={icon} size="3x" />
                    <Typography level="title-md" textAlign="center">
                      {title}
                    </Typography>
                    <Typography level="body-sm" textAlign="center">
                      {description}
                    </Typography>
                  </DqCardContent>
                </DqCard>
              </Grid>
            )
          )}
        </Grid>
      </RadioGroup>
      <AudienceGeneratorStepActionsWrapper>
        <Button onClick={handleBackStep} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={generatorType === null}
          onClick={() => selectGeneratorType(generatorType!)}
          variant="solid"
        >
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </Grid>
  );
});

AdvertiserAudienceGeneratorTypeSelection.displayName =
  "AdvertiserAudienceGeneratorTypeSelection";

export default AdvertiserAudienceGeneratorTypeSelection;
